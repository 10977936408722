import ms from "ms";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@/components/ui/carousel";
import type { Banner } from "@/services/banners";
import Autoplay from "embla-carousel-autoplay";
import { useStore } from "@nanostores/react";
import { $queryMirror } from "../products/list/use-query-params";
import { useEffect, useState } from "react";

export const BannerCarousel = ({ banners: initial }: { banners: Banner[] }) => {
  const { banners } = useBanners(initial);
  const [api, setApi] = useState<CarouselApi>();

  useEffect(() => {
    if (!api) {
      return;
    }

    api.scrollTo(0);
  }, [banners]);

  return (
    <Carousel
      setApi={setApi}
      opts={{ loop: true }}
      plugins={[
        Autoplay({
          delay: ms("6 seconds"),
        }),
      ]}
      className="w-full"
    >
      <CarouselContent>
        {banners.map((banner) => {
          const imagesNode = (
            <>
              <img
                className="md:hidden w-full aspect-[720/240] object-cover"
                src={banner.images.mobileImage}
                alt={banner.name}
              />
              <img
                className="max-md:hidden w-full aspect-[1440/240] object-cover"
                src={banner.images.webImage}
                alt={banner.name}
              />
            </>
          );
          const itemContent =
            banner.redirectTo?.type === "EXTERNAL" ? (
              <a href={banner.redirectTo.target!} target="_blank">
                {imagesNode}
              </a>
            ) : (
              <div>{imagesNode}</div>
            );

          return <CarouselItem key={banner.id}>{itemContent}</CarouselItem>;
        })}
      </CarouselContent>
      {banners.length > 1 && (
        <>
          <CarouselPrevious className="left-2 md:left-4" />
          <CarouselNext className="right-2 md:right-4" />
        </>
      )}
    </Carousel>
  );
};

const useBanners = (initialBanners: Banner[]) => {
  const query = useStore($queryMirror);
  const [banners, setBanners] = useState(initialBanners);

  useEffect(() => {
    const updatedBanners = [];

    const homeBanners = initialBanners.filter((banner) => banner.type === "HOME");
    updatedBanners.push(...homeBanners);

    if (query?.brands) {
      const brands = query.brands.split(",");
      const brandBanners = initialBanners.filter(
        (banner) => banner.type === "BRAND" && banner.typeIds.some((id) => brands.includes(id)),
      );
      updatedBanners.unshift(...brandBanners);
    }

    if (query?.categories) {
      const categories = query.categories.split(",");
      const categoryBanners = initialBanners.filter(
        (banner) =>
          banner.type === "CATEGORY" && banner.typeIds.some((id) => categories.includes(id)),
      );
      updatedBanners.unshift(...categoryBanners);
    }

    setBanners(updatedBanners);
  }, [query]);

  return { banners };
};
